<template>
	<article class="collapsible">
		<ZyroCard
			tag="header"
			class="collapsible__header"
			@click.native="$emit('toggle')"
		>
			<div class="collapsible__heading-container">
				<ZyroSvg
					v-if="icon"
					:name="icon"
					class="collapsible__heading-icon"
				/>
				<h3 class="collapsible__heading z-body z-font-weight-bold">
					<slot name="heading" />
				</h3>
			</div>
			<slot name="tag" />
			<ZyroButton
				class="toggle"
				:class="{ 'toggle--up': isOpen }"
				theme="plain"
				icon="chevron-down-small"
				:title="isOpen ? $t('common.collapse') : $t('common.expand')"
			/>
		</ZyroCard>
		<Transition name="slide-top-to-bottom">
			<div v-show="isOpen">
				<ZyroCard
					v-if="$slots.content"
					tag="main"
					class="collapsible__main"
					:class="{
						'collapsible__main--icon' : icon,
						'collapsible__main--no-top-padding': !isContentWithTopPadding,
					}"
				>
					<slot name="content" />
				</ZyroCard>
				<ZyroCard
					v-if="$slots.footer"
					tag="footer"
					class="collapsible__footer"
					:class="{ 'collapsible__footer--no-content': !$slots.content }"
				>
					<slot name="footer" />
				</ZyroCard>
			</div>
		</Transition>
	</article>
</template>

<script>
import ZyroCard from '@/components/site-settings/components/ZyroCard.vue';

export default {
	components: { ZyroCard },
	props: {
		icon: {
			type: String,
			default: '',
		},
		isOpen: {
			type: Boolean,
			default: false,
		},
		isContentWithTopPadding: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.collapsible {
	background-color: $light;

	&__header:not(.s) { // ensure specificity, because it overrides zyro-card props
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 64px;
		overflow: visible;
		cursor: pointer;

		@include mobile-view {
			justify-content: flex-start;
			padding-right: 32px;
		}
	}

	&__heading:not(.s) {
		margin-right: 32px;
		margin-bottom: 0;

		@include mobile-view {
			margin-right: 8px;
		}
	}

	&__heading-container {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__heading-icon {
		width: 24px;
		height: 26px;
		margin-right: 24px;

		@include mobile-view {
			margin-right: 8px;
		}
	}

	&__main {
		margin-top: -16px;
		margin-bottom: 0;

		&--no-top-padding {
			padding-top: 0;
		}

		&--icon {
			padding-left: 80px;
			margin-top: -32px;
			margin-bottom: 0;

			@include mobile-view {
				padding-left: 16px;
				margin-top: -16px;
			}
		}
	}

	&__footer {
		text-align: right;

		&--no-content {
			padding-top: 4px;
		}
	}
}

.toggle {
	&:not(.s) {
		position: absolute;
		right: 28px;
		transition-property: transform;

		@include mobile-view {
			right: 8px;
		}
	}

	&--up {
		transform: rotate(180deg);
	}
}
</style>
